import React from 'react';
import {CatalogService} from "../../services/catalog-service";
import {Avatar, Icon, List} from "antd";

const listData = [];
for (let i = 0; i < 2; i++) {
    listData.push({
        href: 'http://www.aws.com',
        title: `Research Tool ${i}`,
        avatar: '/images/galaxy_icon.jpeg',
        description:
            'This is a tool that many researchers are going to use',
        content:
            'Doing research on AWS is going to so much easier - You will have the builtin reliability, security, scalability, agility and higher performance all work together for you! ',
    });
}

const IconText = ({type, text}) => (
    <span>
    <Icon type={type} style={{marginRight: 8}}/>
        {text}
  </span>
);

export class CatalogList extends React.Component {

//    searchService = new SearchService();
    catalogService = new CatalogService();

    state = {
        modal2Visible: false,
    };


    componentDidMount() {
        this.setState({result: this.catalogService.getCatalog()})
    }

    
    render() {
        return (
            <div>
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                        onChange: page => {
                            console.log(page);
                        },
                        pageSize: 3,
                    }}
                    dataSource={listData}
                    renderItem={item => (
                        <List.Item
                            key={item.title}
                            actions={[
                                <IconText type="star-o" text="156" key="list-vertical-star-o"/>,
                                <IconText type="like-o" text="156" key="list-vertical-like-o"/>,
                                <IconText type="message" text="2" key="list-vertical-message"/>,
                            ]}
                            extra={
                                <img
                                    width={272}
                                    alt="logo"
                                    src="https://d1.awsstatic.com/Digital%20Marketing/House/Hero/events/reinvent2019/Fallback/Site-Merch_IoT_reInvent-Editorial.8cf843d8f25660e34148eef6c4148a2cd7e8fda1.png"
                                />
                            }
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={item.avatar}/>}
                                title={<a href={item.href}>{item.title}</a>}
                                description={item.description}
                            />
                            {item.content}
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}
