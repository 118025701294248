import React from 'react';
import {Icon, Tabs} from "antd";
import {TrailList} from "./trail-list";
import {CatalogList} from "./catalog-list";
import {PodcastsSearchResult} from "./podcasts-search-result";

const TabPane = Tabs.TabPane;

export class TrailScreenDefault extends React.Component {

    render() {
        return (<div><Tabs defaultActiveKey="1">
            <TabPane tab={<span><Icon type="amazon"/>Catalog</span>} key="1">
                <CatalogList/>
            </TabPane>
            <TabPane tab={<span><Icon type="control"/>Support</span>} key="2">
                <PodcastsSearchResult/>
            </TabPane>
            <TabPane tab={<span><Icon type="control"/>Trail</span>} key="3">
                <TrailList/>
            </TabPane>
        </Tabs></div>)
    }
}
