import React from 'react';
import {Icon, Menu, notification} from "antd";
import {Link} from "react-router-dom";
import {Hub} from "@aws-amplify/core";
import {AuthService} from "../../services/auth-service";
import {Auth} from "aws-amplify";
import { Logger } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';

const logger = new Logger('MyMenuTopMain');

class MyMenuTopMain extends React.Component {
    
    state = {
        loggedIn: false
    };

    constructor(props) {
        super(props);

        Hub.listen(AuthService.CHANNEL, this.onHubCapsule, 'MyListener');
        //logout doesn't know "this" if not bind to "this"
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        logger.debug("componentDidMount");
        this.setUserInState();
    }

    setUserInState () {
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            this.setState({loggedIn: true, currentUser: user.signInUserSession.idToken.payload});
        }).catch(err => {
            logger.info(err);
            this.setState({loggedIn: false, currentUser: ''});
        }); 
    }

    onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;

        //logger.info("Auth event " + JSON.stringify(payload));
        //logger.info(channel + ":" +AuthService.CHANNEL );
        if (channel === AuthService.CHANNEL && payload &&
            (payload.event === AuthService.AUTH_EVENTS.LOGIN || payload.event === 'cognitoHostedUI') ) {
                AuthService.setUserInSession();
                notification.open({
                    type: 'info',
                    message: 'You have logged in',
                    duration: 2
                });
        } else if (channel === AuthService.CHANNEL && payload && payload.event === AuthService.AUTH_EVENTS.SIGN_OUT) {
                this.setState({loggedIn: false, currentUser: ""});
                notification.open({
                    type: 'info',
                    message: 'You have logged out',
                    duration: 2
                });
        } else if (channel === AuthService.CHANNEL && payload && payload.event === AuthService.AUTH_EVENTS.SET_USER_IN_SESSION) {
            if ( payload.success) {
                logger.info(" Set current user to " + JSON.stringify(payload.data));
                this.setState({loggedIn: true, currentUser: payload.data});
            }
        }
    };

    onAuthEvent(payload) {
        // ... your implementation
        logger.info(" payload ----- " + payload)
    }

    logout() {
        AuthService.signOut()
    }

    render() {
        return (<Menu
            theme="light"
            mode="horizontal"
            style={{lineHeight: '63px', backgroundColor: '#FF9900', color: 'white'}}>
            <Menu.Item key="catalog" ><Link to="catalog"><Icon style={{color: 'white'}} type="home"/> <label style={{color: 'white'}}>Home</label> </Link></Menu.Item>
            <Menu.SubMenu title={<Icon type="user"/>}>
                <Menu.ItemGroup title="Settings" style={{ background: '#FF9900', color: 'white'}}>
                    <Menu.Item key="setting:1" ><Link to="settings">Profile</Link></Menu.Item>
                    <Menu.Item key="setting:2" ><Link to="settings">Settings</Link></Menu.Item>
                </Menu.ItemGroup>

                {this.state.loggedIn &&
                <Menu.Item key="auth:1" onClick={this.logout} style={{ backgroundColor: '#FF9900', color: 'white'}}><Icon type="home"/> Log Out</Menu.Item>}
                {!this.state.loggedIn &&
                <Menu.Item key="auth:2" onClick={this.props.OAuthSignIn} style={{ backgroundColor: '#FF9900', color: 'white'}}><Icon type="home"/> Log In</Menu.Item>}
            </Menu.SubMenu>

        </Menu>)
    }
}

export default withOAuth(MyMenuTopMain);