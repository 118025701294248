/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f83c5a79-0f4e-48a3-8f2c-cc0e411f3bfb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IuNo6zoLG",
    "aws_user_pools_web_client_id": "i6bdvta0jsunsn06obj8abe06",
    "oauth": {
        "domain": "myoctank-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "https://web.myoctank.net/",
        "redirectSignOut": "https://web.myoctank.net/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://xxyif6ivujfy5h7lq5v7soxrti.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vxhhrapq5bhwlb4ggtnh2kpe4i"
};


export default awsmobile;
