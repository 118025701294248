import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import './dashboard.css';
import {BrowserRouter as Router} from 'react-router-dom';

const { SubMenu } = Menu;
const {Content, Footer, Sider } = Layout;

export class Dashboard extends React.Component {
    state = {
        collapsed: true,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };
    
    render() 
    {
        return (
          <Router>
            <Layout style={{ height: '100%'}}>
              <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} theme="light" style={{padding: 0}}>
              <div className="logo" />
                <Menu defaultSelectedKeys={['1']} mode="inline">
                  <Menu.Item key="1">
                    <Icon type="pie-chart" />
                    <span>Option 1</span>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Icon type="desktop" />
                    <span>Option 2</span>
                  </Menu.Item>
                  <SubMenu
                    key="sub1"
                    title={
                      <span>
                        <Icon type="user" />
                        <span>User</span>
                      </span>
                    }
                  >
                    <Menu.Item key="3">Tom</Menu.Item>
                    <Menu.Item key="4">Bill</Menu.Item>
                    <Menu.Item key="5">Alex</Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub2"
                    title={
                      <span>
                        <Icon type="team" />
                        <span>Team</span>
                      </span>
                    }
                  >
                    <Menu.Item key="6">Team 1</Menu.Item>
                    <Menu.Item key="8">Team 2</Menu.Item>
                  </SubMenu>
                  <Menu.Item key="9">
                    <Icon type="file" />
                    <span>File</span>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout>
                <Content style={{ margin: '0 16px' }}>
                  <div style={{ padding: 24, height: '100%' }}>Bill is a cat.</div>
                </Content>
                <Footer style={{textAlign: 'center'}}>Research Facilitation as a Service Platform - MyOctank © 2019 </Footer>
              </Layout>
            </Layout>
          </Router>
        );
    };
};