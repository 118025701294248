import {Hub, Logger, API} from 'aws-amplify';

const logger = new Logger('CatalogService');

let apiName = 'rfass';
let path = '/catalog'; 
let myInit = { // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        name: 'param'
    }
}

export class CatalogService {
    CHANNEL = 'catalog_channel';

    CATALOG_EVENTS = {
        RETRIEVE: 'retrieve',
        REQUEST_PERMISSION: 'request_permission'
    };

    getCatalog = async () => {
        API.get(apiName, path, myInit).then(response => {

            logger.info("API Response " + JSON.stringify(response));

            Hub.dispatch(CatalogService.CHANNEL, {
                event: CatalogService.RETRIEVE,
                success: true,
                message: "",
                catalog_list: ''
            }, CatalogService.CHANNEL);

        }).catch(err => {
            //logger.info("Error: " + JSON.stringify(err));
            Hub.dispatch(CatalogService.CHANNEL, {
                event: CatalogService.RETRIEVE,
                success: false,
                message: err.message,
                calalog_list: ''
            }, CatalogService.CHANNEL);

        });

    };
}