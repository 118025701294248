import React from 'react';
import {Col, Layout, Row} from 'antd';
import MyMenuTopMain from "./menu-top-main";
import {Hub} from "@aws-amplify/core";
import {Auth} from "aws-amplify";
import { Logger } from 'aws-amplify';
import {AuthService} from "../../services/auth-service";

const logger = new Logger('Header');

const {Header} = Layout;

export class MyHeader extends React.Component {

    state = {
        loggedIn: false
    };

    constructor(props) {
        super(props);

        Hub.listen(AuthService.CHANNEL, (data) => {
            const {payload} = data;
            //logger.info('A new auth event has happened: ', JSON.stringify(payload));
            // update the app page
            if ( payload && (payload.event === 'singIn' || payload.event === 'cognitoHostedUI') ){
                this.props.handler(true, payload.data);
            } else if ( payload && payload.event === AuthService.AUTH_EVENTS.SET_USER_IN_SESSION) {
                if ( payload.success) {
                    logger.info(" Set current user to " + JSON.stringify(payload.data));
                    this.setState({loggedIn: true, currentUser: payload.data});
                }
            } else {
                this.props.handler(false, payload.data);
            }

        })
    }


    componentDidMount() {
        //logger.info("header component did mount");

        // Check if the user is already logged-in...if so, redirect
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            this.setState({loggedIn: true, currentUser: user});
        }).catch(err => {
            this.setState({loggedIn: false, currentUser: ''});
        });
    }

    render() {
        return (<Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#FF9900', color: 'white'}}>
            <Row gutter={16}>
                <Col span={4}>
                    <label style={{fontFamily: 'fantasy', fontSize: 'large'}}> MyOctank</label>
                </Col>
                <Col span={12}>
                    {this.state.loggedIn && this.state.currentUser && this.state.currentUser.email && "Welcome user: " + this.state.currentUser.email} 
                </Col>
                <Col span={8}>
                    <MyMenuTopMain style={{backgroundColor: '#FF9900'}} />
                </Col>
            </Row>
        </Header>)
    }
}
