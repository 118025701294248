import {Hub, Logger} from 'aws-amplify';
import {AWSAppSyncClient} from 'aws-appsync';

const gql = require('graphql-tag');

const logger = new Logger('TrailService');

const aws_exports = require('../aws-exports').default;

const url = aws_exports.aws_appsync_graphqlEndpoint;
const region = aws_exports.aws_appsync_region;
const type = aws_exports.aws_appsync_authenticationType;
const apiKey = aws_exports.aws_appsync_apiKey;


// Set up Apollo client
const client = new AWSAppSyncClient({
    url: url,
    region: region,
    auth: {
        type: type,
        apiKey: apiKey
    }
    //disableOffline: true      //Uncomment for AWS Lambda
});

// Import gql helper and craft a GraphQL query
const query = gql(`
query AllTrails {
    listTrails {
        nextToken
        items {
          eventname
          id
          identity
          resource
          updatedAt
          createdAt
        }
      }
}`);


export class TrailService {
    static CHANNEL = 'trail_channel';

    static TRAIL_EVENTS = {
        RETRIEVE: 'retrieve'
    };

    getTrails = async () => {
        client.hydrated().then(function (client) {
            //Now run a query
            client.query({ query: query })
                .then(function logData(data) {
                    console.log('results of query: ', data.data.listTrails.items);
                            
                    Hub.dispatch(TrailService.CHANNEL, {
                        event: TrailService.TRAIL_EVENTS.RETRIEVE,
                        success: true,
                        message: "",
                        trail_list: data.data.listTrails.items
                    }, TrailService.CHANNEL);

                })
                .catch(err=> {
                    logger.info("Error: " + JSON.stringify(err));
                    Hub.dispatch(TrailService.CHANNEL, {
                        event: TrailService.TRAIL_EVENTS.RETRIEVE,
                        success: false,
                        message: err.message,
                        trail_list: ''
                    }, TrailService.CHANNEL);

                });
        });
    };
}