import React from 'react';
import './App.css';
import {Layout} from 'antd';
import {MyHeader} from "./components/layout/header";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {AuthScreen} from "./screens/auth/auth-screen";
import Amplify, {Hub} from '@aws-amplify/core'
// AWS Config Files
import awsConfig from './aws-exports.js'
import { SearchScreenDefault } from './screens/search/search-screen-default';
import { TrailScreenDefault } from './screens/search/trail-screen-default';
import { Dashboard} from './screens/search/dashboard'
import { Logger , Auth} from 'aws-amplify';
import { AuthService} from './services/auth-service';

const {Content, Footer} = Layout;

Amplify.configure(awsConfig);

Amplify.Logger.LOG_LEVEL = 'INFO';
const logger = new Logger('App');

export class App extends React.Component {

    state = {
        loggedIn: false
    };

    constructor(props) {
        super(props);

        Hub.listen(AuthService.CHANNEL, this.onHubCapsule, 'MyAppListener');
        //handler doesn't know "this" if not bind to "this"
        this.handler = this.handler.bind(this);
    }

    onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        if (channel === AuthService.CHANNEL &&
            payload.event === AuthService.AUTH_EVENTS.LOGIN) {
            logger.info(" -------- App auth listener - login  event " )
            if (payload.success) {
                this.setState({loggedIn: true, currentUser: payload});
                //this is handler is passed from the parent - call back to update the parent state
            }
        } else if (channel === AuthService.CHANNEL &&
            payload.event === AuthService.AUTH_EVENTS.SIGN_OUT) {
            if (payload.success) {
                logger.info(" -------- App auth listener - logout event " )
                this.setState({loggedIn: false, currentUser: ""});
            }
        }
    };

    componentDidMount() {
        // Check if the user is already logged-in...if so, redirect
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            this.setState({loggedIn: true, currentUser: user});
        }).catch(err => {
            this.setState({loggedIn: false, currentUser: ''});
        }); 
    }

    handler(isloggedin, user) {
        logger.info("------------------- set parent state:" + isloggedin)
        this.setState({loggedIn: isloggedin, currentUser: user})
    }

    render() {
        return (
            <Router>
                <Layout style={{height: '100%'}}>
                    <Layout style={{height: '100%'}}>
                        <MyHeader handler={this.handler}/>
                        {!this.state.loggedIn &&
                        <Content style={{padding: '0 50px', marginTop: 64, width: '100%', height: '100%'}}>
                            <div style={{ padding: 24, width: "100%", marginTop: "20px"}}>
                                <Route exact path='/' component={SearchScreenDefault}/>
                                <Route exact path='/catalog' component={SearchScreenDefault}/>
                                <Route exact path='/trail' component={TrailScreenDefault}/>
                                <Route path={"/login"} component={AuthScreen}/>
                            </div>
                        <Footer style={{textAlign: 'center'}}>Tailbalzer - MyOctank </Footer>
                        </Content>
                        }
                        {this.state.loggedIn &&
                        <Content style={{padding: '0 0px', marginTop: 64, width: '100%', height: '100%'}}>
                            <Route exact path='/' component={Dashboard}/>
                        </Content>
                        }
                    </Layout>
                </Layout>
            </Router>

        );
    }
}

export default App;
