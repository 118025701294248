import React from 'react';
import {TrailService} from "../../services/trail-service";
import {List} from "antd";
import {Hub, Logger} from 'aws-amplify'; 

Logger.LOG_LEVEL = 'DEBUG';

const logger = new Logger('TrailList');

export class TrailList extends React.Component {
    trailService = new TrailService();

    constructor(props) {
        super(props);

        Hub.listen(TrailService.CHANNEL, this.onHubCapsule, 'MyTrailListener');
    }

    onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        logger.info(" -------- Trail retrieve event " + channel + " + " + payload );

        if (channel === TrailService.CHANNEL &&
            payload.event === TrailService.TRAIL_EVENTS.RETRIEVE) {
            if (payload.success) {
                this.setState({trail_list: payload.trail_list});
            }
        } 
    };

    state = {
        modal2Visible: false,
    };

    componentDidMount() {
        this.trailService.getTrails();
    }

    
    render() {
        return (
            <div>
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                        onChange: page => {
                            console.log(page);
                        },
                        pageSize: 3,
                    }}
                    dataSource={this.state.trail_list}
                    renderItem={item => (
                        <List.Item  key={item.eventname}>
                            {item.eventname} : {item.identity} : {item.resource}
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}
